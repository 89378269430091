.flyoutContainer {
  position: absolute;
  top: 100%;
  right: -2rem;
  top: 100%;
  width: 32rem;
}

.flyoutBG {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.container {
  max-height: 70vh;
  overflow-y: auto;
  border-radius: var(--rounded);
}

.dialog {
  box-sizing: border-box;
  width: 100%;
  margin: 1rem 0 0.5rem;
  position: relative;
  z-index: 1;
}

.flexWrapper {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.title {
  composes: fontBody from '../../globalStyles//Typography.module.css';
  font-weight: 400;
  margin: 0;
  color: var(--primaryColour, var(--darkGrey));
  padding: 1.5rem 1.5rem 0;
}

.unreadCount {
  composes: fontBody from '../../globalStyles//Typography.module.css';
  font-weight: 400;
  color: var(--green);
  padding-right: 1.5rem;
}

.cancel {
  composes: fontBody from '../../globalStyles//Typography.module.css';
  margin-left: auto;
}

.cancel svg {
  height: 1.8rem;
  margin-left: 0.2rem;
  position: relative;
  top: -0.1rem;
  vertical-align: middle;
}

.emptyList {
  padding: 2rem;
  text-align: center;
}
