.app {
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.mainContent {
  flex: 1; /* main content should fill remaining space */
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 9; /* should not overlap header */
}

.pageLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 5rem);
}

.pageLoader div {
  width: 60rem;
}

@media print {
  .app {
    position: relative;
  }

  .mainContent {
    overflow: unset;
  }
}
