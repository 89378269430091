.root {
  height: 100%;
  --realmMenuHeight: 6rem;
}

.loading {
  margin: 3rem;
  width: 50%;
}

.menu {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 10;

  max-height: var(--realmMenuHeight);
  transition: max-height 0.15s ease-out;
}

.menu:hover,
.menu:focus,
.menu:focus-within {
  max-height: 100rem;
  transition: max-height 0.25s ease-in;
}

.menu:hover .arrow {
  transform: rotate(180deg);
}

.arrow {
  transform: rotate(0deg);
  position: absolute;
  height: 1rem;
  top: calc(var(--realmMenuHeight) / 2 - 0.5rem);
  right: 1.5rem;
  transition: 0.2s;
  pointer-events: none;
}

.selectedContainer {
  height: var(--realmMenuHeight);
}

.item {
  height: var(--realmMenuHeight);
  width: 100%;
  display: block;
  --maskColour: var(--lightGrey);
}

.item:hover {
  --maskColour: var(--mediumGreen);
}

.mask {
  height: 100%;
  padding: 1.8rem 5rem 1.8rem 1.5rem;
  box-sizing: border-box;

  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.image {
  max-height: 100%;
  max-width: 100%;
}
