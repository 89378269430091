@value footerBreak from '../../globalStyles/breakpoints.module.css';

:root {
  --footerHeight: 3.5rem;
}

.footer {
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  height: var(--footerHeight);
  padding: 0 1.5rem 0 1rem;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
  background-image: url('../../assets/images/footer-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.copy {
  font-size: 1.2rem;
  margin-right: 2.6rem;
  font-weight: bold;
}

.policyLink {
  color: rgba(255, 255, 255, 0.7);
}
.policyLink:hover {
  color: var(--white);
}

.link {
  display: inline-block;
  height: 2rem;
  margin-right: 0.4rem;
  width: 2rem;
}

.icon {
  height: 1.1rem;
  width: 1.1rem;
}

.logo {
  margin: auto;
  height: 2rem;
  margin-top: 1rem;
}

.pitopLink {
  margin: 0 0 0 auto;
}

@media footerBreak {
  .copy {
    font-size: 0.9rem;
    margin-right: 3.6rem;
  }
}
