:root {
  /* Colours */
  --piTopGreen: #00ad9f;
  --darkGreen: #66cec5;
  --mediumGreen: #ccefec;
  --lightGreen: #e0f5f3;
  --navy: #0c212e;
  --mediumNavy: #e9ebec;
  --lightNavy: #f3f4f4;
  --lightGrey: #fafbfb;
  --mediumGrey: #e6e8ea !important; /* needs to override the mediumGrey from react toolbox */
  --grey: #dbdee0;
  --red: #f16f68;
  --mediumRed: #f7a9a4;
  --lightRed: #fce2e1;
  --lightYellow: #fbedbf; /* used only in banner not offially in the pallete ? */
  /* deprecated colours */
  --blue: #42a3ce;
  --lightBlue: rgba(66, 163, 206, 0.5);
  --orange: #ee723e;
  --lightOrange: rgba(238, 114, 62, 0.5);
  --pink: #df1995;
  --lightPink: rgba(223, 25, 149, 0.4);
  --black: #0f212e;
  --lightBlack: rgb(15, 33, 46, 0.7);
  --mediumDarkGrey: #636363;
  --darkGrey: #505050;
  --noteGrey: #878787;
  --iconGrey: #959595;
  --mildGrey: #ececec;
  --white: #fff;

  /* Sizing */
  --rounded: 5px;

  /* Fonts */
  --fontFamily: 'Lato', sans-serif;

  /* React Toolbox */
  --preferred-font: var(--fontFamily);
  --input-text-bottom-border-color: var(--grey);
  --input-text-input-element-color: var(--darkGrey);
}

/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-300italic.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('https://static.pi-top.com/fonts/lato/lato-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('https://static.pi-top.com/fonts/lato/lato-v22-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-regular.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: url('https://static.pi-top.com/fonts/lato/lato-v22-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('https://static.pi-top.com/fonts/lato/lato-v22-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-italic.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-700italic.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://static.pi-top.com/fonts/lato/lato-v22-latin-900italic.svg#Lato')
      format('svg'); /* Legacy iOS */
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
  font-size: 1.6rem;
  line-height: 1.375;
}

::placeholder {
  color: var(--inputColour, var(--iconGrey));
}

html[class~='mask'] div[id='root'] {
  -webkit-filter: blur(3px);
  -o-filter: blur(3px);
  filter: blur(3px);
  filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius='3');
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

*[data-react-beautiful-dnd-drag-handle] {
  outline: none;
}

input,
textarea {
  -webkit-text-fill-color: var(--darkGrey);
  -webkit-opacity: 1;
  opacity: 1;
}

::-webkit-input-placeholder {
  -webkit-text-fill-color: var(--iconGrey);
}

@media print {
  html,
  body {
    size: A4 !important;
  }

  div {
    width: auto !important;
    height: auto !important;
  }

  div[data-testid='masked div'] {
    mask-image: none !important;
  }
}
