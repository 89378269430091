@value medium from '../../globalStyles/breakpoints.module.css';

.navbarButton {
  composes: fontBodyLarge from '../../globalStyles/Typography.module.css';
  background-color: var(--mediumGrey);
  box-sizing: border-box;
  color: var(--buttonColour, var(--darkGrey));
  font-size: 1.6rem;
  font-weight: 600;
  height: 6rem;
  line-height: 6rem;
  min-width: 16rem;
  text-align: center;
}

.active {
  background-color: var(--mediumGreen) !important;
}

a:hover .navbarButton {
  background-color: var(--mediumGreen);
}
