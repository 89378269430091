.wrap {
  max-width: 99rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.tabs {
  margin: 0 auto;
}

.formWrap {
  max-width: 52rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.input {
  text-align: left;
  --textColourBody: var(--noteGrey);
  margin: 5rem 0 0 0;
  padding: 0 8rem;
}

.input label {
  font-size: 1.2rem;
}

.input input {
  font-size: 1.6rem;
  line-height: 1.8;
  outline: none;
  background-color: transparent;
  font-style: italic;
  border: none;
}

.loadingContainer {
  padding: 4rem 0;
}

.loading {
  margin: 4rem auto 2rem;
}

.checkBoxRow {
  margin: 2rem auto 0;
  width: 27rem;
}

.checkBoxText {
  font-size: 1.3rem;
  margin: 0;
  text-align: center;
}

.licenseConfirmationLabel {
  display: block;
  margin: 3rem 0 0 0;
  color: var(--darkGrey);
  font-size: 1.6rem;
  text-align: center;
}

.buttons {
  margin: auto auto 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.btn {
  text-align: center;
  margin: 0 auto;
  width: auto;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0.8rem 3rem;
}

.errorMessage {
  composes: fontSub from '../../globalStyles/Typography.module.css';
  color: var(--red);
  margin: 2rem 0 0;
  text-align: center;
}

.fade {
  opacity: 0.3;
}

.inputContainer {
  margin: 5rem auto 0;
  max-width: 27rem;
  width: 100%;
}

.inputContainer label {
  font-weight: 400;
  font-size: 1.3rem;
}

.error > input {
  border-color: var(--red);
  border-right-color: var(--darkGrey);
}
