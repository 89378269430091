.checkbox:disabled {
  opacity: 0.4;
}

.checkbox:disabled + .label {
  cursor: default;
  opacity: 0.4;
}

.inline {
  display: inline-block;
}

.checkbox {
  left: -9999px;
  opacity: 0;
  position: absolute;
}

.hidden {
  left: -9999px;
  opacity: 0;
  position: absolute;
}

.input {
  background-repeat: no-repeat;
  display: inline-block;
  height: 2.5rem;
  vertical-align: middle;
  width: 2.5rem;
}

.tick {
  background-image: url('../../../assets/images/multi-checked.svg');
}

.monoTick {
  background-image: url('../../../assets/images/multi-checked-mono.svg');
}

.checked {
  composes: tick;
  background-repeat: no-repeat;
  display: block;
  height: 2.2rem;
  margin: -0.5rem 0 0 0.5rem;
  width: 2rem;
}

.squareCheck {
  background-size: 2rem 2rem;
  background-position: -0.2rem 0.2rem;
}

.hidden {
  visibility: hidden;
}

.label {
  composes: fontBody from '../../../globalStyles/Typography.module.css';
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0.6rem 0;
  font-size: 1.8rem;
}

.labelText {
  display: flex;
  flex: 1;
  align-items: center;
}

.labelText > svg {
  margin: 0 0.75rem;
  height: 2rem;
  width: 2rem;
}

.leftLabel {
  margin: 0;
  margin-right: 15px;
}

.rightLabel {
  margin: 0;
  margin-left: 15px;
}

.errorMsg {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: var(--red);
}

.noCheckIcon .input {
  display: none;
}

.singleSelectUi {
  composes: fontBody from '../../../globalStyles/Typography.module.css';
  font-size: 1.6rem;
  mask-image: url('../../../assets/images/search-input-mask.svg');
  mask-size: 100% 100%;
}

.checkbox:not(:disabled) + .singleSelectUi.isSelected {
  background: var(--lightGreen);
  color: var(--black);
}

.twoColOptionBox {
  background-color: #f7f7f7;
  margin: 0 5px 5px 10px;
  min-height: 84px;
  display: grid;
  padding-left: 3rem;
  padding-right: 2rem;
  height: 50px;
}

.twoColOptionBox > label > span:nth-child(1) {
  font-weight: 900;
  font-style: normal;
  font-size: 2.1rem;
}

.twoColOptionBox > label > span:nth-child(2) {
  font-style: normal;
  font-size: 2.1rem;
}

.twoColOptionBox > label > span:nth-child(3) {
  height: 4rem;
  width: 4rem;
}

.twoColOptionBox > label > span:nth-child(3) > span {
  height: 3.5rem;
  width: 3.5rem;
}
