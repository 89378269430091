/* @value medium from '../../globalStyles/breakpoints.module.css'; */
@value smallish from '../../globalStyles/breakpoints.module.css';
/* @value small from '../../globalStyles/breakpoints.module.css'; */

.backdrop {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.hidden {
  visibility: hidden;
}

.hidden * {
  visibility: hidden; /* don't let children override this visibility */
}

.mask {
  max-width: 99rem;
  width: 100%;
  height: 100%;
  max-height: 67rem;
  display: flex;
}

.dialog {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--darkGrey);
  background: var(--white);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.container {
  background-color: var(--white);
  height: 100%;
  overflow: hidden;
}

.close {
  height: 1.3rem;
  margin-left: auto;
  width: 1.3rem;
  position: absolute;
  top: 1.3rem;
  right: 1.3rem;
  opacity: 1;
  color: var(--iconGrey);
  mask-image: url('../../assets/images/wonky-close.svg');
  mask-repeat: no-repeat;
  background-color: var(--darkGrey);
  z-index: 1;
  transition: background-color 0.1s ease-out;
}

.close:hover {
  background-color: var(--piTopGreen);
}

.closeBackdrop > path {
  opacity: 1;
  fill: var(--white);
}

.large .close {
  top: 1.5rem;
  right: 2rem;
}

/*======== Medium size dialog CSS ========*/
.medium .mask {
  max-width: 65rem;
  max-height: 41.5rem;
}

.medium .close {
  right: 1.6rem;
}

/*======== Small size dialog CSS ========*/
.small .mask {
  max-width: 45.2rem;
  max-height: 29rem;
  margin: 0;
}

@media screen and (max-width: 1000px) {
  .mask {
    margin: 8.5rem 2rem;
  }
}

@media smallish {
  .headerContainer {
    padding: 2.5rem 2.5rem 0;
  }
}

@media screen and (max-width: 640px) {
  .large .close {
    top: 1.3rem;
    right: 1.3rem;
  }

  .medium .close {
    right: 1.3rem;
  }

  .medium .mask,
  .small .mask,
  .mask {
    max-width: calc(100vw - 30px);
  }
}
