.userMenuButton {
  height: 5.5rem;
  width: 5.5rem;
  margin-right: 1.5rem;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  border: none;
}

.menuItem:hover {
  background-color: var(--lightGreen);
}

.menuItem:focus {
  outline: 1px solid var(--piTopGreen);
}

.menuToggle:focus {
  outline: 1px solid var(--piTopGreen);
}

.customRow {
  color: var(--darkGrey);
  display: flex;
  justify-content: space-between;
  height: 5rem;
  padding: 0;
  line-height: 5rem;
}

.divider {
  position: relative;
  top: -3rem;
}

.trialButton {
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: capitalize;
}

.trialButton:hover {
  color: var(--piTopGreen);
}

.dropdownMenu {
  right: 1.5rem;
  z-index: 10;
}

.linkDropdownItem {
  color: var(--darkGrey);
  text-decoration: none;
}

.linkDropdownItem:hover {
  background-color: var(--lightGreen);
}

.linkDropdownItem a {
  display: block;
  color: var(--darkGrey);
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  height: 100%;
  width: 100%;
}

.linkDropdownItem a:hover {
  color: var(--piTopGreen);
}
