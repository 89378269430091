.wrap {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.text {
  margin: 0 0 3rem;
  text-align: center;
}

.progressBar {
  width: 69%;
}
