.f {
  fill: #ffffff;
}

.triangle {
  fill: #00ad9f;
}

.wrapper {
  height: 2.4rem;
  width: 2.4rem;
  margin: 0 2.2rem 0 0;
}
