.root {
  margin: 12rem auto 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.wrapper {
  margin: 0 auto;
  max-width: 41.5rem;
  text-align: center;
  color: var(--darkGrey);
}

.wrapper h3 {
  text-align: center;
  padding: 0;
  margin: 0;
}

.wrapper img {
  margin-top: 3.7rem;
  margin-left: -0.8rem;
  padding: 0;
}

.wrapper .message {
  padding: 3.7rem 0 0 0;
  font-size: 1.6rem;
}

.wrapper button {
  margin-top: 9rem;
  width: 15rem;
  color: var(--piTopGreen);
  text-decoration: none;
  padding: 0 0.6rem;
}

.wrapper .footer {
  padding: 6rem 0;
  font-size: 1.6rem;
}

.wrapper .footer a {
  color: var(--piTopGreen);
  font-size: 1.6rem;
  text-decoration: none;
  padding: 0 0.6rem;
}
