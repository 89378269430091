.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 10;
  height: 100%;
}

.button {
  composes: fontBody from '../../globalStyles/Typography.module.css';
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100%;
}

.login {
  margin-right: 15px;
}
