.bell {
  position: absolute;
  z-index: 1;
  width: 5.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unreadCount {
  position: absolute;
  z-index: 2;
  top: 1rem;
  right: 0.8rem;
}

.unreadCount > svg {
  width: 1.6rem;
}

.unreadCount > span {
  position: absolute;
  font-size: 1rem;
  color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
