.root {
  height: 5.5rem;
  width: 5.5rem;
  position: relative;
  vertical-align: middle;
  border: none;
  font-size: 4rem;
  color: var(--darkGrey);
}

.circleIcon {
  height: inherit;
  width: inherit;
}

.threeDots {
  width: 5rem;
  height: 5rem;
  background-image: url(../../assets/images/three-dot-icon.svg);
  background-size: 44px;
  background-repeat: no-repeat;
  background-position: center;
}

.content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
}

.closeIcon {
  width: 5rem;
  height: 5rem;
  color: var(--iconGrey);
  background-image: url('../../assets/images/wonky-close.svg');
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center;
}
