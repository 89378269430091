.defaultNavMenuButton {
  height: 5.5rem;
  width: 5.5rem;
  position: relative;
  vertical-align: middle;
  border: none;
  font-size: 3rem;
  color: var(--darkGrey);
  top: 0.2rem;
}

.defaultNavMenuButtonContent {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
}

.defaultNavMenuButtonContent span {
  margin-bottom: 2rem;
}

.defaultNavMenuButtonContent .closeIcon {
  height: 3.5rem;
  margin-bottom: 0.5rem;
  width: 3.5rem;
  display: block;
}

.dropdownMenu {
  top: 8rem;
  z-index: 10;
}
