.btn {
  composes: fontSub from '../../globalStyles/Typography.module.css';
  min-height: 33px;
  min-width: 128px;
  color: var(--btnColour, var(--piTopGreen));
  cursor: pointer;
  background-color: transparent;
}

.btn:hover {
  background-color: var(--mediumGreen);
}

.btn:focus-visible {
  outline: 2px solid var(--piTopGreen);
  outline-offset: 3px;
}

.btn:disabled {
  background-color: transparent;
  cursor: default;
}

.buttonText {
  min-height: 20px;
  composes: fontSub from '../../globalStyles/Typography.module.css';
  font-size: 14px;
  line-height: 1.3;
  color: var(--piTopGreen);
  font-weight: 800;
  white-space: nowrap;
}

.border {
  border-style: solid;
  border-color: var(--piTopGreen);
  border-width: 2px;
  border-top-left-radius: 2rem 50%;
  border-top-right-radius: 2rem 50%;
  border-bottom-right-radius: 2rem 50%;
  border-bottom-left-radius: 2rem 50%;
  padding: 0.3rem 2.5rem 0.2rem 2.5rem;
}
