@value logoS from '../../globalStyles/breakpoints.module.css';

.logo {
  background-image: url('../../assets/images/nav-logo.svg');
  height: 6.4rem;
  width: 14.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media logoS {
  .logo {
    height: 6.2rem;
    width: 7.3rem;
    background-image: url('../../assets/images/nav-logo-small.svg');
  }
}
