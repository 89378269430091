.root {
  background-color: var(--maskColour, var(--lightGrey));
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  position: relative;
}

.border {
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.border.active {
  visibility: initial;
}
