.card {
  padding: 0 1.5rem;
  box-sizing: border-box;
}

.unreadBG {
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.unreadBG path {
  fill: var(--green);
}

.cardBody {
  width: 100%;
  color: var(--darkGrey);
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}

.messageBody {
  display: flex;
}

.icon > svg {
  height: 5rem;
  width: 5rem;
}

.message > span,
.message > strong {
  margin-right: 0.5rem;
}

.message > a {
  margin-right: 0.5rem;
  color: var(--darkGrey);
  font-weight: 600;
  text-decoration: none;
}

.userName {
  color: var(--darkGrey);
}

.unknownValue {
  color: var(--grey);
}

.cardFooter {
  margin: 0;
  color: var(--iconGrey);
}
