.bordered {
  --inputBackground: transparent;
  --inputBorderFocus: transparent;
  --inputBorderRadius: 0;
}

.label {
  composes: fontBody from '../../../globalStyles/Typography.module.css';
  display: block;
  margin: 0 0 1rem;
}

.input {
  composes: fontSub from '../../../globalStyles/Typography.module.css';
  background-color: var(--inputBackground, var(--white));
  border: 1px solid var(--inputBorder, var(--inputBackground, var(--grey)));
  border-radius: var(--inputBorderRadius, var(--rounded));
  box-sizing: border-box;
  color: var(--inputColour, var(--darkGrey));
  padding: 0.5rem 2rem;
  transition: border ease-in-out 0.2s;
  width: 100%;
}

.input:focus-visible {
  outline: 2px solid var(--piTopGreen);
  border-radius: 5px;
}

.bordered .input {
  background-image: url(../../../assets/images/dialog-input.svg);
}

.bordered .input:focus-visible {
  outline: none;
  background-image: url(../../../assets/images/dialog-input-focus.svg);
}

.bordered .input:invalid {
  background-image: url(../../../assets/images/dialog-input-error.svg);
}

.bordered .input:read-only {
  background-image: url(../../../assets/images/dialog-input.svg);
}

.bordered .input:disabled {
  background-image: url(../../../assets/images/dialog-input-disabled.svg);
}

.input:focus {
  border-color: var(--inputBorderFocus, var(--piTopGreen));
}

.bordered .input {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 1rem 2rem;
}

.input:disabled {
  opacity: var(--disabledInputOpacity, 0.5);
}

.helpText {
  color: var(--inputColour, var(--darkGrey));
  font-size: 1.2rem;
}
