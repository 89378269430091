.button {
  composes: fontBodyLarge from '../../../globalStyles/Typography.module.css';
  transition: color 0.2s;
}

.button:focus-within:not(:disabled),
.button:hover:not(:disabled) {
  color: var(--piTopGreen);
}

.button:not(:disabled) {
  cursor: pointer;
}

.button:disabled {
  opacity: 0.5;
}

.styled {
  composes: button;
  background-color: var(--buttonBackground, var(--lightGreen));
  border: 1px solid
    var(--buttonBorder, var(--buttonBackground, var(--piTopGreen)));
  border-radius: var(--buttonBorderRadius, var(--rounded));
  color: var(--buttonColour, var(--darkGrey));
  height: 5rem;
  min-width: 15rem;
  padding: 0 1.5rem;
  text-align: center;
}

.unstyled {
  composes: button;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}
