.root {
  width: 52px;
  height: 52px;
  rotate: 180deg;
}

.path {
  fill: none;
  stroke: #505050;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
