.notificationContainer {
  position: relative;
  float: right;
  display: flex;
  flex-direction: column;
  line-height: normal;
  z-index: 10;
}

.bellIcon {
  line-height: 1;
  position: relative;
  width: 5.7rem;
  height: 5.7rem;
}
