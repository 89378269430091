@value medium from '../../globalStyles/breakpoints.module.css';
@value small from '../../globalStyles/breakpoints.module.css';
@value smallish from '../../globalStyles/breakpoints.module.css';

:root {
  --navBarHeight: 6.5rem;
}

.header {
  align-items: center;
  background-image: url('../../assets/images/nav-background.svg');
  background-color: var(--lightGrey);
  display: flex;
  justify-content: space-between;
  color: var(--navy);
  height: var(--navBarHeight, 6.5rem);
  line-height: 8rem;
  width: 100%;
  z-index: 10;
}

.logoContainer {
  align-self: stretch;
}

.logo {
  position: relative;
  top: -3px;
  height: 6.5rem;
}

.realmMenu {
  height: 100%;
  width: 17rem;
}

.spacer {
  flex-grow: 1;
}

.buttonBar {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
}

.menu {
  background-color: var(--lightGrey);
  bottom: 0;
  right: -2px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateY(100%);
  width: 20rem;
}

.menuItem {
  box-sizing: border-box;
  composes: fontBodyLarge from '../../globalStyles/Typography.module.css';
  color: var(--navy);
  display: block;
  padding: 2rem 1rem;
  text-decoration: none;
  width: 100%;
}

.menuItem:hover {
  background-color: var(--lightGreen);
}

.menuItem:focus {
  outline: 1px solid var(--piTopGreen);
}

.iconContainer > div {
  margin-left: 1rem;
}

.greeting {
  composes: fontBodyLarge from '../../globalStyles/Typography.module.css';
  color: inherit;
  align-self: center;
}

.notifications,
.defaultDropdown,
.signUpHandler {
  margin-right: 15px;
}

.buttonLink {
  text-decoration: none;
  outline: none;
}

.buttonLink:focus-visible {
  outline: 2px solid var(--piTopGreen);
  border-radius: 5px;
}

.mobileDropdown {
  display: none;
}

/* breakpoint for when 4 buttons in menu */
@media medium {
  .buttonBar > a:nth-last-of-type(4),
  .buttonBar > a:nth-last-of-type(4) ~ a {
    display: none;
  }
  .buttonBar > a:nth-last-of-type(4) ~ .mobileDropdown {
    display: block;
  }
  .buttonBar > a:nth-last-of-type(4) ~ .defaultDropdown {
    display: none;
  }
}

/* breakpoint for when 3 buttons in menu */
@media smallish {
  .buttonBar > a:nth-last-of-type(3),
  .buttonBar > a:nth-last-of-type(3) ~ a {
    display: none;
  }
  .buttonBar > a:nth-last-of-type(3) ~ .mobileDropdown {
    display: block;
  }
  .buttonBar > a:nth-last-of-type(3) ~ .defaultDropdown {
    display: none;
  }
}

/* breakpoint for less than 3 buttons in menu */
@media small {
  .buttonBar {
    justify-content: flex-end;
  }
  .buttonLink {
    display: none;
  }
  .mobileDropdown {
    display: block;
  }
  .defaultDropdown {
    display: none;
  }
}
