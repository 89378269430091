.mobileMenuButton {
  height: 5.5rem;
  width: 5.5rem;
  position: relative;
  border: none;
  font-size: 3rem;
  color: var(--darkGrey);
  top: 0.2rem;
}

.dropdownContainer {
  margin-right: 1.5rem;
}

.mobileMenuButtonContent {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
}

.mobileMenuButtonContent span {
  margin-bottom: 2rem;
}

.icon {
  height: 3.5rem;
  margin-bottom: 0.5rem;
  width: 3.5rem;
  display: block;
}

.mobileMenuButtonContent .closeIcon {
  width: 5rem;
  height: 5rem;
  color: var(--iconGrey);
  background-image: url('../../assets/images/wonky-close.svg');
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center 1.3rem;
}

.dropdownMenu {
  top: 7rem;
  right: -10rem;
  z-index: 10;
}
