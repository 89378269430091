@value smallish from '../../globalStyles/breakpoints.module.css';

.dialog {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: var(--darkGrey);
}

.container {
  background-color: var(--white);
  position: relative;
}

.headerContainer {
  flex: 0 0 auto;
  padding: 5rem 5rem 0;
}

.bodyContainer {
  flex: 1 1;
  overflow-y: auto;
  padding: 3.4rem 5rem 0;
}

.footerContainer {
  flex: 0 0 auto;
  padding: 0 5rem 4rem;
}

.bodyContainer .innerContainer {
  min-height: 100%;
}

.innerContainer {
  max-width: 70.8rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.headerIcon {
  max-height: 9.6rem;
  margin: 2rem 0 2.5rem;
  text-align: center;
}
.headerIcon img {
  max-height: 9.6rem;
}

.title {
  composes: fontH2T1 from '../../globalStyles/Typography.module.css';
  font-weight: 700;
  margin: 0;
  font-size: 1.8rem;
  text-align: center;
}

.description {
  composes: fontSub from '../../globalStyles/Typography.module.css';
  text-align: center;
  margin: 0;
}

.footer {
  composes: innerContainer;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.backAction {
  composes: fontBody from '../../globalStyles/Typography.module.css';
  position: absolute;
  left: 0;
}

/*======== Medium size dialog CSS ========*/

.medium .headerContainer {
  padding: 2.5rem 2.5rem 0;
}

.medium .bodyContainer {
}

.medium .headerIcon {
  margin: 0.5rem 0 0;
}

.medium .title {
  margin-top: 2.5rem;
}

/*======== Small size dialog CSS ========*/

.small .headerContainer {
  padding: 2.5rem 2.5rem 0;
}

.small .bodyContainer {
}

.small .footerContainer {
  padding: 0 2.5rem 5rem;
}

.small .headerIcon {
  margin: 0;
}

.small .title {
  margin-top: 2rem;
}

@media screen and (max-width: 1000px) {
  .mask {
    margin: 8.5rem 2rem;
  }

  .headerContainer {
    padding: 2.5rem 5rem 0;
  }

  .bodyContainer {
  }

  .headerIcon {
    margin: 0;
  }

  .title {
    margin-top: 2.5rem;
  }

  .footer div {
    max-width: 100%;
  }
}

@media smallish {
  .headerContainer {
    padding: 2.5rem 2.5rem 0;
  }

  .bodyContainer {
    padding: 3.4rem 2.5rem 0;
  }

  .footer div {
    max-width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .medium .mask,
  .small .mask,
  .mask {
    max-height: calc(100vh - 17rem);
  }
}
