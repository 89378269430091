.separatorBefore::before {
  content: '';
  margin-right: 15px;
  background-image: url('../assets/images/vertical-separator.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 5px;
  height: 30px;
}

.separatorAfter::after {
  content: '';
  margin-left: 15px;
  background-image: url('../assets/images/vertical-separator.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 5px;
  height: 30px;
}
