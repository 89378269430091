@value medium from '../../globalStyles/breakpoints.module.css';

.container {
  position: relative;
  --circleIconFill: var(--mediumGrey);
  --maskColour: var(--white);
}

.menu {
  width: 27.5rem;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  left: auto;
  padding: 0.2rem;
  background: url('../../assets/images/multi-check-box.svg') no-repeat
    transparent;
  background-size: 100% 100%;
  display: none;
}

.menu.opened {
  display: block;
}

.mask {
  height: 5rem;
  padding: 0 1.5rem;
  line-height: 5rem;
}

.item {
  display: block;
  color: var(--darkGrey);
  --maskColour: var(--white);
}

.item:hover,
.item.selected {
  --maskColour: var(--lightGreen);
}

.item.button {
  font-size: 1.6rem;
  text-align: left;
  width: 100%;
}

.noUserId {
  display: block;
}
