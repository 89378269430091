.container {
  display: grid;
  place-items: center;
  font-size: 25px;
  height: 100vh;
  color: var(--darkGrey);
  opacity: 0;
  animation: fadeIn 5s;
  animation-fill-mode: forwards;
}

.hourglassIcon {
  position: relative;
  top: 0.7rem;
  height: 3rem !important;
  width: 3rem !important;
  animation: rotation 2s infinite linear;
}

.hourglassIcon g path {
  stroke: var(--piTopGreen);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
